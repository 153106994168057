/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState } from 'react';
import { graphql, navigate } from 'gatsby';
import Layout from '../components/layout';
import Content from '../components/content';
import Grid from '../components/grid';
import Card from '../components/card';
import { getSrc } from 'gatsby-plugin-image';
import Slider from 'react-slick';
import { homePageShowcase } from '../data/data';

import '../styles/index.scss';

const settings = {
  autoplaySpeed: 8000,
  arrows: false,
  dots: true,
  infinite: true,
  speed: 800,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  clickable: true,
  lazyLoad: 'progressive'
};

const Index = ({ data }) => {
  const [isSwiping, setSwiping] = useState(false);
  const { projectsShowcase, prjCategories } = data;

  const metaData = {
    title: 'ПР Строй ООД'
  };
  return (
    <Layout metaData={metaData}>
      <Slider {...settings}>
        {homePageShowcase.map((item) => {
          const queryData = projectsShowcase.edges.find((query) => item.image === query.node.base);
          return (
            <div
              role="button"
              tabIndex="0"
              key={queryData.node.id}
              onMouseDown={() => {
                setSwiping(false);
              }}
              onMouseMove={() => {
                setSwiping(true);
              }}
              onMouseUp={(e) => {
                if (!isSwiping && e.button === 0) {
                  navigate(item.url);
                }
              }}
              onTouchStart={() => {
                setSwiping(false);
              }}
              onTouchMove={() => {
                setSwiping(true);
              }}
              onTouchEnd={(e) => {
                e.preventDefault();

                if (!isSwiping) {
                  navigate(item.url);
                }
              }}
            >
              <div className="image-slide" style={{ backgroundImage: `url(${getSrc(queryData.node)})` }}>
                <div className="slide-desc">
                  <h1 className="slide-title backdrop">{item.name}</h1>
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
      <Content type="full">
        <Grid>
          {prjCategories.edges.map((item, i) => {
            const project = item.node.frontmatter;
            return (
              <Card
                key={`key${i}`}
                title={project.title}
                bg={project.coverImage ? getSrc(project.coverImage) : null}
                url={item.node.fields.slug.slice(9, item.node.fields.slug.length - 1)}
              />
            );
          })}
        </Grid>
      </Content>
    </Layout>
  );
};

export default Index;

export const query = graphql`
  query index {
    projectsShowcase: allFile(filter: { relativePath: { regex: "/images/projects-showcase/" } }) {
      edges {
        node {
          id
          base
          ...largeImage
        }
      }
    }
    prjCategories: allMarkdownRemark(
      filter: { frontmatter: { category: { eq: true } } }
      sort: { fields: [frontmatter___index], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            index
            title
            coverImage {
              ...largeImage
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
